import { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from 'react-router-dom'
import Footer from "../component/Footer";
import Header from "../component/Header";
import LazyLoad from "react-lazyload";

const News = () => {

   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState();
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const currentYear = new Date().getFullYear();
      setSelectedYear(currentYear.toString());  // Set to current year
      console.log("Current Year:", currentYear);  // Ensure correct year
   }, []);

   useEffect(() => {
       const fetchData = async () => {
           const galleryData = await getNewsInner(); 
           setData(galleryData); 

           // Extract unique years
           const uniqueYears = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.fromDate) {
                       const year = new Date(item.fromDate).getFullYear();
                       return [year];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueYearRanges = uniqueYears.map(year => `${year}`);
           uniqueYearRanges.sort((a, b) => b - a);
           setYearRanges(uniqueYearRanges);

           // Extract unique months
           const uniquemonth = Array.from(
               new Set(galleryData.flatMap((item) => {
                   if (item.fromDate) {
                       const month = new Date(item.fromDate).getMonth() + 1;
                       return [month];
                   } else {
                       return [];
                   }
               }))
           );
           const uniqueMonths = uniquemonth.map(month => `${month}`);
           uniqueMonths.sort((a, b) => b - a);
           setMonthOptions(uniqueMonths);
       };
       fetchData();
   }, []);

   useEffect(() => {
       let filteredData = data;

       // Step 1: Apply year and month filters
       if (selectedYear !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.fromDate && item.fromDate.trim() !== "") {
                   const year = new Date(item.fromDate).getFullYear();
                   const month = new Date(item.fromDate).getMonth() + 1;
                   return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
               } else {
                   return false;
               }
           });
       }

       // Step 2: Apply month filter if selected
       if (selectedMonth !== "All") {
           filteredData = filteredData.filter((item) => {
               if (item.fromDate && item.fromDate.trim() !== "") {
                   const month = new Date(item.fromDate).getMonth() + 1;
                   return parseInt(selectedMonth) === month;
               } else {
                   return false;
               }
           });
       }

       // Step 3: Apply search filter
       if (searchQuery) {
           filteredData = filteredData.filter((item) =>
               item.title.toLowerCase().includes(searchQuery.toLowerCase())
           );
       }

       // Step 4: Handle no data for current year and fallback to previous year
       const currentYear = new Date().getFullYear();
       const currentYearData = filteredData.filter((item) => {
           const getDatefilter = new Date(item.fromDate).getFullYear();
           return getDatefilter === currentYear;
       });

       // If no data for current year, fallback to previous year
       if (currentYearData.length === 0) {
           const previousYearData = data.filter((item) => {
               const getDatefilter = new Date(item.fromDate).getFullYear();
               return getDatefilter === currentYear - 1;
           });
           filteredData = previousYearData; // Use previous year's data if current year has no data
       } else {
           filteredData = currentYearData; // Use current year's data if available
       }

       // Step 5: Sort the filtered data by date in descending order
       filteredData.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));

       // Step 6: Update state with filtered data
       setFilteredData(filteredData);
   }, [selectedYear, selectedMonth, searchQuery, data]);

   return (
      <>
      <Header />
      <div className="breadcrumb">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="d-flex">
                     <h2>Latest News</h2>
                     <ul>
                        <li><Link to="/">Home</Link></li>
                        <li>|</li>
                        <li>Latest News</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <section className="innerbody">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="button-list-block">
                     <Link to="/Events">  <button>Events</button></Link>
                     <Link to="/News" className="active"> <button>News</button></Link>
                  </div>
               </div>
            </div>
            <div className="row tabs-dynamic">
               <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                  <div className="count-val">
                     <p>Total Count: {filterData.length} </p>
                  </div>
               </div>
               <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                  <div className="month-selection">
                     <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="yearSelection">
                        {/* <option value={"All"}>All</option> */}
                        {yearRanges.map((yr) => (
                           <option key={yr} value={yr}>{yr}</option>
                        ))}
                     </select>
                  </div>
               </div>
               <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                  <div className="month-selection">
                     <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="monthSelect"
                     >
                        <option value={"All"}>All</option>
                        {monthOptions.map((month, index) => (
                           <option key={index} value={month}>{month}</option>
                        ))}
                     </select>
                  </div>
               </div>
               <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                  <div className="searchBox">
                     <input
                        type="text"
                        id="myInput"
                        name="name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search Here.."
                     />
                  </div>
               </div>
            </div>

            <div className="row years year2023 showYear">
               {filterData.length > 0 ? (
                  filterData.map((item, index) => (
                     <div className="col-md-12 newsCount" key={index}>
                        <div className="inr-news-blk">
                           <div className="inr-event-img">
                              <LazyLoad>
                                 {item.images && item.images.length > 0 ? (
                                    <img src={`https://webapi.entab.info/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />
                                 ) : (
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about.jpg" className="img-fluid mx-auto d-block" alt="St. george Collage Musoori" />
                                 )}
                              </LazyLoad>
                           </div>
                           <div className="inr-news-content">
                              <h6 className="date">{item.fromDate}</h6>
                              <h3>{item.title}</h3>
                              <p>{item.description}</p>
                              <h6 className="loc">
                                 {item.attachments && item.attachments.length > 0 && (
                                    <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"> 
                                       <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                                    </Link>
                                 )}
                              </h6>
                           </div>
                        </div>
                     </div>
                  ))
               ) : (
                  <div className="col-md-12 newsCount">
                     <div className="inr-news-blk">
                        <div className="inr-event-img">
                           <LazyLoad>
                              <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt" />
                           </LazyLoad>
                        </div>
                        <div className="inr-news-content">
                           <h6 className="date">DD.MM.YYYY</h6>
                           <h3>News Title</h3>
                           <p>Stay Tuned For More updates</p>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </section>
      <Footer />
      </>
   );
}

export default News;
