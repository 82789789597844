import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
import LazyLoad from 'react-lazyload';

const CareerInformation = () => {

    return (
        <>
            <Header />
            <div className="breadcrumb mb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Career Information, Education & Guidance</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Career Information, Education & Guidance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className='container'>

                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 class="inner-main-title"><span class="dark-bg">Career Information, </span> <span class="light-bg"> Education & Guidance</span></h3>
                        </div>
                        <div className='col-md-12'>
                            <p>At St. George’s College, Mussoorie, we believe that preparing students for life beyond school is as important as their academic journey. Our Career Information, Education, and Guidance (CIEG) program is designed to equip students with the knowledge, skills, and insights necessary to make informed career choices in an ever-evolving global landscape.</p>
                            <p>### Career Information, Education & Guidance</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xxl-12 col-lg-12'>
                            <p>At <b>St. George’s College, Mussoorie, </b>we understand the importance of guiding students toward successful career paths. Our Career Information, Education, and Guidance (CIEG) program is designed to offer students the tools, resources, and knowledge they need to navigate their future academic and professional endeavors.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career1.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>Comprehensive Career Counseling</h3>
                            <p>Our career counseling team provides personalized support to students from middle school through senior years. Through one-on-one sessions, psychometric tests, and regular workshops, we help students identify their strengths, skills, and interests, enabling them to make informed career choices.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career2.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>Confluential Program</h3>
                            <p>Our unique Confluential Program brings together students, educators, industry professionals, and alumni to provide a well-rounded career guidance experience. This program offers an integrated approach to career education by connecting students with leaders from various industries and academic fields. Through interactive sessions, students gain insights into career trends, opportunities in diverse fields, and how to align their academic journey with future professional goals.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career3.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>University & College Placement Support</h3>
                            <p>We maintain collaborations with leading universities in India and abroad, ensuring students have access to the best higher education opportunities. Our placement support team offers guidance on entrance exams, scholarships, and application processes. We also host university fairs and connect students with representatives from renowned institutions globally.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career4.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>Workshops & Seminars</h3>
                            <p>Our program includes skill-enhancing workshops that cover essential topics such as resume building, interview techniques, public speaking, and professional networking. These seminars help students develop confidence and soft skills that are crucial for success in both academic and professional environments.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career5.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>Internship & Industrial Visits</h3>
                            <p>Real-world experience is a key element of our CIEG program. We provide opportunities for students to participate in internships and industrial visits, offering firsthand exposure to various careers. Students gain valuable insights by engaging directly with professionals across industries.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-4 col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/SGSM/public/Images/career6.jpg" className='img-fluid' alt="St. George collage, musoori" /></LazyLoad>
                        </div>
                        <div className='col-xxl-8 col-lg-8'>
                            <h3>Global Career Opportunities</h3>
                            <p>With an emphasis on global awareness, St. George’s College offers students opportunities to explore international careers through student exchanges, participation in global conferences, and interactions with foreign universities. This global exposure prepares them to thrive in an interconnected world.</p>
                        </div>
                    </div>

                    <div className='row strip_view'>
                        <div className='col-xxl-12 col-lg-12'>
                            <h3>Alumni Network & Mentorship</h3>
                            <p>Our extensive Alumni Network plays a vital role in the CIEG program. Through the Alumni Mentorship Program, students receive guidance from accomplished alumni who offer advice on career choices, higher education, and professional development.   </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
export default CareerInformation