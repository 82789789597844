import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"

const Residential = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Residential Facilities</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Residential Facilities </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Residential</span> <span className="light-bg">Facilities</span> </h3>
                        </div>
                        
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/hotel.png" alt="St. George’s College"  /></LazyLoad>
                                </div>
                                <p className="inner-title"><span>Boarding Houses</span></p>
                                <p>St. George's College provides comfortable and secure boarding facilities for its students. The boarding houses are equipped with modern amenities and are supervised by experienced staff to ensure a safe and nurturing environment.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/dinning-room.png" alt="St. George’s College"  /></LazyLoad>
                                </div>
                                <p className="inner-title"><span>Dining Hall</span></p>
                                <p>The spacious dining halls offer nutritious and balanced meals prepared by professional chefs. The dining halls are separated for vegetarians and non-vegetarians. The menu is designed to cater to diverse dietary preferences and ensure the overall health and well-being of the students.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/hospital.png" alt="St. George’s College"  /></LazyLoad>
                                </div>
                                <p className="inner-title"><span>Hospital and Infirmary</span></p>
                                <p>The college has a well-equipped hospital and infirmary with qualified medical staff to provide immediate medical care and attention. Regular health check-ups and medical services are available to ensure the well-being of the students. The school also has three hi-tech, modern ambulances in case of any emergency.
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Residential