
import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"

const RulesRegulation = () => {
    return (
        <>
            <Header />

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>RULES AND REGULATIONS</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Admission</li> <li>|</li>
                                    <li>RULES AND REGULATIONS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">RULES</span> <span className="light-bg"> AND REGULATIONS</span></h3>


                        </div>
                        <div className="col-md-12">
                            <div class="d-flex rules-reg">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/absent.png" alt="St. George’s College"  /></LazyLoad>
                                <div>
                                    <p className="inner-title">OUTINGS/LEAVES</p>
                                    <p>Parents/guardians or those authorized by the parents are permitted to take their wards out only on the days prescribed by the School Calendar. Visitors are not allowed to see the students without the expressed permission of the Principal. Students are also not allowed to see the visitors in the school without the Principal’s permission.</p>
                                </div>
                            </div>
                            <div class="d-flex rules-reg">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/paying.png" alt="St. George’s College"  /></LazyLoad>
                                <div>
                                    <p className="inner-title">POCKET MONEY</p>
                                    <p>Students can open an account in the State Bank of India, St. George’s College, Barlowganj, Mussoorie. They are permitted to withdraw a limited amount per week from their bank accounts. Students are also given a regular amount of pocket money through the school. They should not be encouraged to keep large sums of money with them. Money should also not be kept with teachers and other staff members. Unlimited pocket money leads to unhealthy practices.</p>
                                </div>
                            </div>
                            <div class="d-flex rules-reg">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/rules.png" alt="St. George’s College"  /></LazyLoad>
                                <div>
                                    <p className="inner-title">GENERAL RULES</p>
                                    <p>All correspondence to and from the student is controlled by the Principal.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default RulesRegulation