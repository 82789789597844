import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"
const Sports = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Sports and Extracurricular Facilities</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Sports and Extracurricular Facilities </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Sports and</span> <span className="light-bg"> Extracurricular Facilities</span></h3>
                            <div className="col-md-12">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/swimming.png" alt="St. George’s College"  /></LazyLoad>
                                </div>
                                <p className="inner-title"><span>Indoor Heated Swimming Pool</span></p>
                                <p>Swimming is a key aspect of SGC’s sports curriculum, emphasizing discipline, safety, and personal growth. At St. George’s
                                College, we are committed to providing a safe and secure environment for students to learn and practice swimming. Our
                                swimming pool is not only an integral part of the sports program but also a dedicated space where student safety is prioritized
                                above all. </p> 
                                <p><b>1.</b> Certified Instructors: All instructors are CPR, First Aid, and Lifeguarding certified and trained to handle emergencies.</p>
                                <p><b>2.</b> Constant Supervision: Students are always under vigilant adult supervision during swimming sessions.</p>
                                <p><b>3.</b> Strict Safety Protocols: We strictly follow all safety rules to ensure a secure environment.</p>
                                 <p><b>4.</b> Emergency Preparedness: The pool is equipped with essential emergency amenities, including:</p>
                                 <p><b>•</b> First Aid Kit </p>
                                 <p><b>•</b> Oxygen Cylinder </p>
                                 <p><b>•</b> Life Buoy Rings </p>
                                 <p><b>•</b> Rescue Ropes </p>
                                <p><b>5.</b> Constant Monitoring: HD cameras are installed to constantly monitor activities, ensuring prompt response in case of
                                any concern.</p>
                                <p><b>6.</b> Experienced Staff: Our instructors are experienced, attentive, and committed to student safety</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                           <div className="inner-facility"><LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/certifiate1.PNG" className="img-fluid" alt="St. George’s College" /></LazyLoad></div>
                        </div>
                        <div className="col-md-6">
                        <div className="inner-facility"><LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/certifiate2.PNG" className="img-fluid" alt="St. George’s College" /></LazyLoad></div>
                        </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/indoor.png" alt="St. George’s College" /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Indoor Sports Complex</span></p>
                                    <p>St. George's College has an extensive sports complex that includes facilities for basketball, volleyball, badminton, squash and table tennis. The complex also features a well-equipped gymnasium for physical fitness and conditioning.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/football.png" alt="St. George’s College" /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Playing Fields</span></p>
                                    <p>The college boasts expansive playing fields for cricket, football, hockey, and athletics. These fields provide ample space for students to engage in outdoor sports and develop their athletic skills.</p>
                                </div>
                            </div>
                          
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/music.png" alt="St. George’s College"  /></LazyLoad>
                                    </div>
                                    <p className="inner-title"><span>Modern Music and Dance Studio</span></p>
                                    <p>The School has a dedicated space for music and dance. It includes all modern instruments and sound systems enabling students to explore their creative talents and participate in various cultural activities.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                                <div className="inner-facility">
                                                    <div className="inner-facility_img">
                                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/CRICKET.png" alt="St. George’s College" /></LazyLoad>
                                                    </div>
                                                    <p className="inner-title"><span>INDOOR CRICKET STADIUM</span></p>
                                                    <p> The college has a dedicated indoor cricket stadium, allowing students to practice and play the sport regardless of weather conditions. The facility is equipped with
                                            a professional training environment, including an automatic bowling machine that helps students enhance their batting and reflexes.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="inner-facility">
                                                    <div className="inner-facility_img">
                                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/SHOOTING.png" alt="St. George’s College" /></LazyLoad>
                                                    </div>
                                                    <p className="inner-title"><span>SHOOTING RANGE</span></p>
                                                    <p>St. George’s College also houses a state-of-the-art electronic shooting range, offering students the opportunity to train and excel in shooting sports under expert
                                            supervision.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="inner-facility">
                                                    <div className="inner-facility_img">
                                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/BILLIARD.png" alt="St. George’s College" /></LazyLoad>
                                                    </div>
                                                    <p className="inner-title"><span>BILLIARD ROOM</span></p>
                                                    <p>The college has a well-maintained billiard room, providing students with a space to develop focus, strategy, and precision through the game of billiards.</p>
                                                </div>
                                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Sports