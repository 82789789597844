import React, { useState } from 'react';
import '../Assets/css/menu.css'
import { Link } from 'react-router-dom';
import OnlineRegistration from './OnlineRegistration';
import LazyLoad from 'react-lazyload';

const Header = () => {
    const [isOpen, setIsOpen] = useState();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsOpen(window.innerWidth > = 991);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    return (
        <div className="header ">

            <div className='d-flex'>
                <div className="header-contact desk-only">
                    <div>
                        <p><LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/whatsapp.png" alt="St. George's College" ></img></LazyLoad> <a href="https://api.whatsapp.com/send?phone=7060311770">+91 7060311770</a></p>
                        <p><LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/email.png" alt="St. George's College" ></img></LazyLoad> <a href="mailto: principal@sgconline.ac.in">principal@sgconline.ac.in</a></p>
                    </div>
                </div>
                <div className='admission-btn'>
                <OnlineRegistration />
                </div>
                <div className='logo'>
                    <Link to="/">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/logo.png"  alt="St. George's College" /></LazyLoad>
                    </Link>
                </div>
                <div className='imp-link'>
                    {/* <p><a href="https://eazypay.icicibank.com/eazypayLink?P1=nobKVwAfXEbUGARH+ty/Mg==" target='_blank' rel="noreferrer">Online Fee Payment</a></p> */}
                    <p><a href="https://epay.sgconline.ac.in" target='_blank' rel="noreferrer">Online Fee Payment</a></p>
                </div>
                <div className='menu-btn'>
                    <button className="menu-toggle" onClick={toggleMenu}>
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/menu-btn.png"  alt="St. George's College" /></LazyLoad>
                    </button>
                </div>
            </div> {isOpen && (
                <div>
                    <div class="back-overlay" onClick={toggleMenu}></div>
                    <div className='crossMe' onClick={toggleMenu}>
                        <i class="bi bi-x-lg"></i>
                    </div>
                    <div className="menu">

                        <div className='logo'>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/logo.png" alt="St. George's College"  /></LazyLoad>
                        </div>

                        <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">
                            {/* <li><i class="bi bi-house-door-fill"></i></li> */}
                            <li>About
                                <ul className="sub-submenu">
                                    <li><Link to="/about">About Us</Link></li>
                                    {/* <li>Administration</li>
                                    <li>Our Patrons</li>
                                    <li>Faculty</li> */}
                                    <li><Link to="/mission-vision">Our Mission & Vision</Link></li>
                                    <li><Link to="/principal-message">Principal's Message</Link></li>
                                    <li><Link to="/timeline">Timeline</Link></li>
                                    <li><Link to="/schoolSong">School Song</Link></li>
                                    {/* <li>School Information</li> */}
                                    <li><Link to="/philosophy">Our Philosophy</Link></li>
                                    <li><Link to="/values">Our Values</Link></li>
                                    <li><Link to="/aims-objectives"> Aims & Objectives</Link></li>
                                    {/* <li>Activities</li> */}
                                </ul>
                            </li>
                            <li>
                                Academics
                                <ul className="sub-submenu">
                                    {/* <li>Syllabus</li>
                                    <li>School Timings</li>
                                    <li>Result</li>
                                    <li>curriculum</li> */}
                                    <li><Link to="https://webapi.entab.info/api/image/SGSM/public/pdf/weekly-menu.pdf">Weekly Menu</Link></li>
                                    <li><Link to="https://webapi.entab.info/api/image/SGSM/public/pdf/Calendar_2024_SGC.pdf" target="_blank">Academic Calender</Link></li>
                                </ul>
                            </li>
                            <li>
                                Admission
                                <ul className="sub-submenu">
                                    <li><Link to="/entry-to-sgc">Entry to SGC</Link></li>
                                    <li><Link to="/entry-to-class-iV-Viii">Entry to Class IV – VIII</Link></li>
                                    <li><Link to="/entry-to-IX">Entry to Class IX</Link></li>
                                    <li><Link to="/entry-to-XI">Entry to Class XI</Link></li>
                                    <li><Link to="/virtual-tour">Virtual Tour</Link></li>
                                    <li><Link to="https://webapi.entab.info/api/image/SGSM/public/pdf/Fee-breakup-for-handbook.pdf" target="_blank">Fees Structure 2025</Link></li>
                                    <li><Link to="/rules-regulations">Fees Rules And Regulations</Link></li>
                                </ul>
                            </li>
                            {/* <li>
                                Student Corner
                                <ul className="sub-submenu">
                                    <li>Primary Wing </li>
                                    <li>Senior Wing</li>
                                    <li>Student Clubs</li>
                                    <li>Clubs and Association</li>
                                    <li>Syllabus</li>
                                    <li>School Houses</li>
                                </ul>
                            </li> */}
                            <li>
                                Facilities
                                <ul className="sub-submenu">
                                    <li><Link to="/academic-facilities">Academic Facilities</Link></li>
                                    <li><Link to="/sports">Sports and Extracurricular Facilities</Link></li>
                                    <li><Link to="/residential">Residential Facilities</Link></li>
                                    <li><Link to="/campusAmenities">Campus Amenities</Link></li>
                                    <li><Link to="/environmental">Environmental Initiatives</Link></li>
                                </ul>
                            </li>
                            <li>
                                Gallery
                                <ul className="sub-submenu">
                                    <li><Link to="/Gallery">Photo Gallery</Link></li>
                                    {/* <li>Video Gallery</li> */}
                                    <li><Link to="/newsletter">Newsletter</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;