import React, { useState } from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";

const Newsletter = () => {
    // State to manage selected year and tab
    // const [selectedYear, setSelectedYear] = useState('2025');
    const [selectedTab, setSelectedTab] = useState('man_year');

    // Sample data, should ideally come from props or an API
    const content = {      
        '2024': {
            man_year: [
                // { id: 1, link: "https://drive.google.com/file/d/1IYdOrktJ0mxl8FijSdT0_Fix_MY_sFkK/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter6.jpg", title: "The Manorite" },
                { id: 4, link: "https://drive.google.com/file/d/1dPAtpr28PI7s_cmHb-dt3V1irpgGUK1v/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter5.jpg", title: "Manor Musings 2023" },
            ],
            man_quater: [
                { id: 2, link: "https://drive.google.com/file/d/1ubpEQT2RlUKl8zelq0S7ITs4Jj_xHEO4/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter8.jpg", title: "The Manor Times" },
                { id: 17, link: "https://drive.google.com/file/d/1r1-i1RUTX_Urqap9tNjj-fQ1Uv92l_QB/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter17.jpg", title: "The Manor Times" },
            ],
            man_monthly: [
                { id: 13, link: "https://drive.google.com/file/d/1E_262bGLnkgmb3Y7uwWJAgjWm3ZM8mNJ/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings9.jpg", title: "March 2024 Edition" }, 
                { id: 3, link: "https://drive.google.com/file/d/1rsf37yQVPhsAqWxPbvIqysJIzyyKg2K-/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings1.jpg", title: "April 2024 Edition" },
                { id: 15, link: "https://drive.google.com/file/d/1-xLhd_mQyDtQK80vIGfORa2PbUCnOIJu/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings11.jpg", title: "May 2024 Edition" },
                { id: 9, link: "https://drive.google.com/file/d/1kOAU4fFRa9XniTs76HzAIiI1334NccZ-/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings5.jpg", title: "June 2024 Edition" },
                { id: 8, link: "https://drive.google.com/file/d/1NTn_JmB5AGfamUNlZxkeNJdxyw7wNSQF/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings4.jpg", title: "July 2024  Edition" },
                { id: 5, link: "https://drive.google.com/file/d/1c4_3PCwBrHo-bYjjlY-qaWhyN_EkF8m0/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings2.jpg", title: "August 2024 Edition" },
                { id: 12, link: "https://drive.google.com/file/d/1Fzvngq4rE37WE6QBWtI-j_fMT-dHTF-f/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings8.jpg", title: "September 2024 Edition" },
                { id: 10, link: "https://drive.google.com/file/d/1tLKGK2Dtdn2TDFdjiXi96GLOyhvRiF9n/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings6.jpg", title: "October 2024 Edition" }, 
                { id: 16, link: "https://drive.google.com/file/d/1rOp7HtnXt0y1lSj7_Oyx4brMbhq89ov_/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings12.jpg", title: "November 2024 Edition" },
                { id: 6, link: "https://drive.google.com/file/d/1eDnCj84g4y-OynC5gbX0XFG2cuhEpwP2/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/musings3.jpg", title: "December 2024 Edition" }
            ],
            man_handbook: [
                { id: 18, link: "https://drive.google.com/file/d/18MrqP3GTiiMaaKxvD1s_4iOkOlriltdZ/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter18.jpg", title: "Parents' Handbook 2025" },
                { id: 7, link: "https://drive.google.com/file/d/1J7byi1pkxP6dgr6Nu7-WLJEexM42e8sw/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter7.jpg", title: "Parents' Handbook 2024" }
            ],
            man_culture: [
                { id: 19, link: "https://drive.google.com/file/d/1dNUk0YUpFAQoO0-VJIWKK8tJNQwNLcfH/view?usp=drive_link", img: "https://webapi.entab.info/api/image/SGSM/public/Images/newsletter19.jpg", title: "Culture" }
            ]
        } 
    };

    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>NewsLetter</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Gallery</li>
                                    <li>|</li>
                                    <li>E-NewsLetter</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section newsletter">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className="inner-main-title">
                                <span className="dark-bg">E - </span>
                                <span className="light-bg"> Newsletter</span>
                            </h3>
                        </div>
                        <div className='col-md-12'>
                            <div className="yearSelection">
                                
                                {/* <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} style={{display: "none"}}>
                                    <option value="2025">2025</option>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                </select> */}
                            </div>
                            <div className="tabSelect">
                                <div 
                                    className={`tab ${selectedTab === 'man_year' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_year')}
                                >
                                    Manorite - YearBook
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_quater' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_quater')}
                                >
                                    Manor Times - Quarterly Magazine
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_monthly' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_monthly')}
                                >
                                    Manor Musings - Monthly Magazine
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_handbook' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_handbook')}
                                >
                                    Parents Handbook
                                </div>
                                <div 
                                    className={`tab ${selectedTab === 'man_culture' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('man_culture')}
                                >
                                    Culture
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                {content[2024][selectedTab].map((item) => (
                                    <div key={item.id} className='col-lg-4 col-md-6'>
                                        <div className="card">
                                            <Link to={item.link} target="_blank">
                                                <LazyLoad><img src={item.img} className="img-fluid" alt={item.title} /></LazyLoad>
                                                <h3>{item.title}</h3>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Newsletter;
