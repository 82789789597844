import React, { useEffect, useState } from 'react';
import { getBirthdays, getBirthdaysAll } from '../Service/Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LazyLoad from 'react-lazyload';

const Birthday = () => {
    const [data, setData] = useState([]); // State for today's birthdays
    const [dataAll, setDataAll] = useState([]); // State for all birthdays this month

    useEffect(() => {
        const fetchData = async () => {
            const birthdayData = await getBirthdays();
            setData(birthdayData);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchDataAll = async () => {
            const birthdayDataAll = await getBirthdaysAll();
            const filterData = birthdayDataAll.filter((item)=>{
                const month = new Date(item.date).getMonth();
                const currentMonth = new Date().getMonth();
                const getThisMonth = month === currentMonth
                return getThisMonth;
            })
            setDataAll(filterData);
        };
        fetchDataAll();
    }, []);

    const settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        controls: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 4,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
            1600: {
                items: 5,
            }
        }
    };

    const settings1 = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        controls: true,
        items: 5,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            },
            1600: {
                items: 2,
            }
        }
    };

    return (
        <>
            <div className='col-md-4'>
                <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/gift-gif.gif" className="gift-right" alt="Gift" /></LazyLoad>
                <div className='birthday-block'>
                    <div className="upcoming">
                        <h4>TODAY'S STARS</h4>
                    </div>
                    <OwlCarousel className='owl-theme birthday-theme remove-cont' loop margin={10} {...settings1}>
                        {data.length > 0 ? data.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="birthday-content">
                                    
                                    <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Birthday" /></LazyLoad>
                                    <p>{item.name}</p>
                                    <p className="primary">{item.class}</p>
                                </div>
                            </div>
                        )) : (<div className="item">
                        <div className="birthday-content">
                            <h6>DD.MM.YYYY</h6>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/birthday-dummy.png" alt="Birthday" /></LazyLoad>
                            <p>Student Name</p>
                            <p className="primary">Class</p>
                        </div>
                    </div>)}
                    </OwlCarousel>
                </div>
            </div>

            <div className='col-md-8'>
                <div className="upcoming">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/gift.gif" alt="Gift" /></LazyLoad>
                    <h4>Birthday's This Month</h4>
                </div>
                <div>
                    <OwlCarousel className='owl-theme birthday-theme' loop margin={10} {...settings}>
                        {dataAll.length > 0 ? dataAll.map((item, index) => {
                            const date = new Date(item.date);
                            const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;

                            return (
                                <div className="item" key={index}>
                                    <div className="birthday-content">
                                        <h6>{formattedDate}</h6>
                                        <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Birthday" /></LazyLoad>
                                        <p>{item.name}</p>
                                        <p className="primary">{item.class}</p>
                                    </div>
                                </div>
                            );
                        }) : ((<div className="item">
                        <div className="birthday-content">
                            <h6>DD.MM.YYYY</h6>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SGSM/public/Images/birthday-dummy.png" alt="Birthday" /></LazyLoad>
                            <p>Student Name</p>
                            <p className="primary">Class</p>
                        </div>
                    </div>))}
                    </OwlCarousel>
                </div>
            </div>
        </>
    );
}

export default Birthday;
