import { useEffect, useState } from "react";
import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";
import Footer from "../component/Footer";
import Header from "../component/Header";

const Gallery = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear.toString());  // Set to current year
    console.log("Current Year:", currentYear);  // Ensure correct year
  }, []);

  const convertDateToDDMMYYYY = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getGalleryInner();
      const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');

      // Convert date to DD MM YYYY format
      const formattedGalleryData = galleryData.map(item => ({
        ...item,
        formattedDate: item.date ? convertDateToDDMMYYYY(item.date) : null,
      }));

      setData(formattedGalleryData);

      const yearsSet = new Set();
      const monthsSet = new Set();

      formattedGalleryData.forEach(item => {
        if (item.date) {
          const date = new Date(item.date);
          yearsSet.add(date.getFullYear());
          monthsSet.add(date.getMonth());
        }
      });

      const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
      setYearRanges(uniqueYearRanges);

      const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
      setMonthOptions(["All", ...monthOptions]);
    };

    fetchData();
  }, []);
  useEffect(() => {
    let filteredData = data;

    // Step 1: Apply year and month filters
    if (selectedYear !== "All") {
      filteredData = filteredData.filter(item => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const date = new Date(itemDate);
          const year = date.getFullYear();
          const month = date.getMonth();
          return year === parseInt(selectedYear) && (selectedMonth === "All" || monthNames[month] === selectedMonth);
        }
        return false;
      });
    } else if (selectedMonth !== "All") {
      filteredData = filteredData.filter(item => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const month = new Date(itemDate).getMonth();
          return monthNames[month] === selectedMonth;
        }
        return false;
      });
    }

    // Step 2: Check if current year data exists
    const currentYear = new Date().getFullYear();
    const currentYearData = filteredData.filter((item) => {
      const getDatefilter = new Date(item.date).getFullYear();
      return getDatefilter === currentYear;
    });

    // Step 3: If no data for the current year, fallback to previous year
    if (currentYearData.length === 0) {
      console.log("No data for current year, falling back to previous year.");
      // Fallback to previous year data
      const previousYearData = data.filter((item) => {
        const getDatefilter = new Date(item.date).getFullYear();
        return getDatefilter === currentYear - 1;
      });
      filteredData = previousYearData; // Use previous year's data if current year has no data
    } else {
      filteredData = currentYearData; // Use current year's data if available
    }

    // Step 4: Apply search filter
    if (searchQuery) {
      filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    // Step 5: Sort by date in descending order
    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Step 6: Update state with filtered data
    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);


  const handleModal = id => {
    navigate(`/SubGallery?id=${id}`);
  };

  const emptyArray = [
    {
      description: "Sample description when no data available.",
      attachments: ["director.jpg"]
    }
  ];

  return (
    <>
      <Header />
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <h2>Photo Gallery </h2>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>|</li>
                  <li>Photo Gallery </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Categories: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  {/* <option value="All">All</option> */}
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect">
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row years year2023 showYear">
            {filterData.length > 0 ? (
              filterData.map((item, index) => (
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount" key={index}>
                  <div className="gal_new">
                    <article>
                      <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} alt="St George College musoori" className="img-fluid" />
                      <div className="gal-content">
                        <h4 className="catgry-name">{item.title}</h4>
                        <p>{item.description}</p>
                        <h6 className="date"><i className="bi bi-calendar-week"></i> {item.formattedDate}</h6>
                      </div>
                    </article>
                  </div>
                </div>
              ))
            ) : (
              emptyArray.map((data, index) => (
                <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                  <div className="gal_new">
                    <article>
                      <img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="St George College musoori" className="img-fluid" />
                      <div className="gal-content">
                        <h4 className="catgry-name">Category Name</h4>
                        <p><strong>Description: </strong>Stay Tuned for Latest Updates</p>
                        <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY
                          <span> <Link to="/SubGallery">View Album <i className="bi bi-arrow-right"></i> </Link></span>
                        </h6>
                        <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                      </div>
                    </article>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
